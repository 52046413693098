body {
    margin: 0;
}

html,
body,
#root {
    font-family: 'Roboto, Arial, Sans-serif' !important;
}

input::placeholder,
textarea::placeholder,
textarea::-moz-placeholder,
textarea::-webkit-input-placeholder {
    opacity: 1 !important;
    color: #333 !important;
    font-size: 12px !important;
    font-family: 'Roboto, Arial, Sans-serif' !important;
}

em {
    color: #333 !important;
}
.rrt-title {
    font-size: 14px !important;
    margin-bottom: 0 !important;
}
.rrt-text {
    font-size: 14px;
}

pre {
    font-size: 18px !important;
}

iframe {
    pointer-events: none;
}
